$primary-color: #009BD1;
$color-accent:  #4C9DCF;
$page-padding: 20px;
$background: #FAFAFA;

:export {
  // Export the color palette to make it accessible to JS
  primary-color: $primary-color;
  page-padding: $page-padding;
  color-accent: $color-accent;
  background-color: $background;
  // etc...
}
