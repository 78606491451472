@import './theme';

* {
  font-family: 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background: $background;
}

.card-shadow-light {
  box-shadow: 0 3px 20px rgba(143, 143, 143, 0.1);
}

.card-shadow-dark {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
}

.card {
  background: white;
  @extend .card-shadow-light;
}

.page-card {
  position: relative;
  margin: 5px;
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  background: white;
  @extend .card-shadow-light;
}

.page {
  display: flex;
  flex-direction: column;
  padding: $page-padding;
}

.center-in-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@for $i from 1 through 6 {
  h#{$i} {
    padding: 5px;
    margin: 0px;
  }
}

h1 {
  font-weight: 400;
}

h1 {
  font-size: x-large;
}

ol.list {
  padding-left: 20px;
  li {
    padding-top: 30px;
    color: #5b5454;
  }
}

.button {
  padding: 6px 15px;
  border: 0px;
  font-weight: 700;
font-size: 12px;
line-height: 14px;
  border: 1px solid transparent;
  border-radius: 10px;
  &.primary {
    background: $color-accent;
    color: white;
  }&.error{
    background: red;
    color:white;
  }
  &.hollow {
    background: white;
    border: 1px solid $color-accent;
    color: $color-accent;
  }
  &:disabled,
  &.disabled {
    background: #9f9090 !important;
    cursor:not-allowed !important;
  }
}
.button:hover{
  opacity:0.8;
  color:white;
  cursor:pointer;
}
p {
  font-weight: 300;
  font-size: small;
}
.link {
  color: $color-accent;
}

.text-center {
  text-align: center;
}

.light-text {
  opacity: 0.7;
}

.form-item {
  display: flex;
  flex-direction: column;

  label {
    font-size: xx-small;
    font-weight: 400;
    padding-bottom: 5px;
  }
}

select {
  background-color: white;
  border: 1px solid #5b545444;
  min-width: 200px;
}

.two-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  width: 25%;
}

.one-button-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  width: 25%;
}

.border-card {
  padding: 10px;
  border: 1px solid #00000020;
  border-radius: 5px;
}

.spin {
  animation: spin 3s linear infinite;
}
p{
  margin:0;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
